<template>
    <div :class="`card mb-4 ${extraClasses}`" :style="setHeight">
        <template v-if="noHeader === false">
            <template v-if="$slots.header">
                <div class="card-header text-center">
                    {{name}}
                    <div class="float-end">
                        <slot name="header"></slot>
                    </div>
                </div>
            </template>
            <template v-else>
                <div class="card-header text-center" v-html="name"></div>
            </template>
        </template>
        <div class="card-body" :style="hasHeight">
            <p class="card-subtitle" v-if="subtitle">{{subtitle}}</p>
            <slot></slot>
        </div>
        <div class="card-footer text-body-secondary" v-if="showFooter">
            <slot name="footer"></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "BootstrapCard",
    props: {
        name: {
            type: String,
            required: true,
        },
        noHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
        fixedHeight: {
            type: String,
            required: false,
            default: null
        },
        showFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        headerButton: {
            type: Object,
            required: false,
            default: null,
        },
        subtitle: {
            type: String,
            required: false,
            default: null,
        },
        marginBottom: {
            type: String,
            required: false,
            default: null,
        },
        extraClasses: {
            type: String,
            required: false,
            default: null,
        }
    },
    computed: {
        setHeight() {
            let extra = {};
            if (this.fixedHeight !== null) {
                extra = {
                    'height': this.fixedHeight,
                    'min-height': this.fixedHeight,
                    'max-height': this.fixedHeight
                };
            }
            if (this.marginBottom) {
                extra['margin-bottom'] = this.marginBottom;
            }
            return extra;
        },
        hasHeight() {
            if (this.fixedHeight === null) {
                return false;
            } else {
                return {
                    'height': `calc(${this.fixedHeight} - 50px)`,
                    'min-height': `calc(${this.fixedHeight} - 50px)`,
                    'max-height': `calc(${this.fixedHeight} - 50px)`,
                    'overflow-y': 'auto',
                    'overflow-x': 'hidden',
                    'padding': '1rem'
                };
            }
        }
    }
}
</script>
