import MainTheme from "@/layout/MainTheme.vue";
import LimitedTheme from "@/layout/LimitedTheme.vue";
import Functions from "@/libs/Functions";
import store from "@/system/store";

const isLimited = Functions.isLimited();

import {createRouter, createWebHistory} from 'vue-router';

// console.log("Svr BasePath", Functions.getServerSetting('base-path', '/'), process.env.BASE_URL);

const basePath = process.env.BASE_URL || '/';
// if (window.location.pathname.substring(0, 3) === '/ui') {
//     basePath = '/ui';
// }

let children = [];

if (isLimited) {
    children.push({
        path: 'login',
        name: 'Login',
        component: () => import('@/views/user/Login')
    });
}
children.push({
    path: 'dashboard',
    name: 'Dashboard',
    component: () => import('@/views/user/Dashboard')
});
if (!isLimited) {
    children.push({
        path: 'configuration',
        name: 'Configuration',
        component: () => import('@/views/user/Configuration')
    });
    // children.push({
    //     path: 'configuration2',
    //     name: 'Configuration2',
    //     component: () => import('@/views/user/Config')
    // });
}
if (isLimited) {
    if (Functions.getServerSetting('iam', '1') === '1') {
        children.push({
            path: 'iam',
            name: 'IAM',
            component: () => import('@/views/user/IAM')
        });
    }
} else {
    children.push({
        path: 'iam',
        name: 'IAM',
        component: () => import('@/views/user/IAM')
    });
}
if (!isLimited) {
    children.push({
        path: 'provider-profiles',
        name: 'ProviderProfiles',
        component: () => import('@/views/user/ProviderProfiles')
    });
    children.push({
        path: 'provider-profiles/:profile',
        name: 'ProviderProfile',
        component: () => import('@/views/user/ProviderProfiles')
    });
    children.push({
        path: 'provider-profiles-2/:action*',
        name: 'ProviderProfiles2',
        component: () => import('@/views/user/ProviderProfiles2')
    });
    children.push({
        path: 'provider-profiles-3/:action*',
        name: 'ProviderProfiles3',
        component: () => import('@/views/user/ProviderProfiles3')
    });
}
children.push({
    path: 'file-manager/:pathMatch(.*)*',
    name: 'FileManager',
    component: () => import('@/views/user/FileManager')
});
if (!isLimited) {
    children.push({
        path: 'logs',
        name: 'Logs',
        component: () => import('@/views/user/Logs')
    });
    children.push({
        path: 'system',
        name: 'System',
        component: () => import('@/views/user/System')
    });
}
children.push({
    path: '/knowledge-base',
    name: 'KnowledgeBase',
    component: () => import('@/views/user/KnowledgeBase')
});

const routes = [
    {
        path: basePath,
        component: isLimited ? LimitedTheme : MainTheme,
        redirect: `${basePath}dashboard`,
        children: children
    },
];

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
let router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    base: basePath,
    routes, // short for `routes: routes`
});
router.beforeEach((to, from, next) => {
    if (to.hash !== "") {
        next(to.fullPath.split('#')[0]);
        return;
    }
    if (Functions.isLimited()) {
        if (store.getters['isLoggedIn']) {
            next();
        } else {
            if (to.name !== 'Login') {
                next({name: 'Login'});
            } else {
                next();
            }
        }
    } else {
        next();
    }
    // console.log("from", from);
    // console.log("next", next);
});

export default router;
