import axios from 'axios';
import Functions from "@/libs/Functions";
import EventBus from "@/libs/EventBus";

class AJAX {

    async get(url, version) {
        if (version === undefined || version === null) {
            version = 1;
        }

        return await new Promise((resolve, reject) => {
            axios.get(`${Functions.getOriginPath()}api/v${version}/${url}`, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        reject(401);
                    }
                    if (error.message === 'Network Error') {
                        reject(error.message);
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async post(url, data) {
        return await new Promise((resolve, reject) => {
            axios.post(`${Functions.getOriginPath()}api/v1/${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    console.error(error);
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async upload(url, data, retries = 3) {
        let headers = this.getConfigs().headers;
        headers['Content-Type'] = 'multipart/form-data';
        for (let attempt = 1; attempt <= retries; attempt++) {
            try {
                const response = await axios.post(`${Functions.getOriginPath()}api/v1/${url}`, data, {
                    headers: headers,
                    onUploadProgress: (progressEvent) => {
                        let percent = (progressEvent.loaded / progressEvent.total) * 100;
                        EventBus.emit('UploadProgress', {
                            progress: percent,
                            current: progressEvent.loaded,
                            total: progressEvent.total,
                            event: progressEvent,
                            data: data,
                        });
                    },
                });
                return {success: true, response: response.data};
            } catch (error) {
                if (attempt === retries) {
                    return { success: false, error: error };  // If the last attempt fails, return the error
                }
                console.log(`Retry attempt ${attempt} failed, retrying...`);
            }
        }
    }

    async put(url, data) {
        return await new Promise((resolve, reject) => {
            axios.put(`${Functions.getOriginPath()}api/v1/${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async delete(url, data) {
        return await new Promise((resolve, reject) => {
            let configs = this.getConfigs();
            configs.data = data;
            axios.delete(`${Functions.getOriginPath()}api/v1/${url}`, configs)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        window.UI.logout();
                    } else {
                        reject(error);
                    }
                });
        });
    }

    async rawGet(url) {
        return await new Promise((resolve, reject) => {
            axios.get(`${url}`, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }

    async rawPost(url, data) {
        return await new Promise((resolve, reject) => {
            axios.post(`${url}`, data, this.getConfigs())
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    }


    getConfigs() {
        return {
            headers: {
                service: 'XNSRelayer',
                request: new Date(),
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
            }
        };
    }

}

export default new AJAX();
