class Functions {

    static isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }

    static getOriginPath() {
        if (Functions.isDevelopment()) {
            let currentPath = location.origin.split(':');
            currentPath = currentPath[0] + ':' + currentPath[1] + ':' + 8875; // Admin Port & Non MT
            // currentPath = currentPath[0] + ':' + currentPath[1] + ':' + 8825; // UI Port
            return currentPath + process.env.BASE_URL;
        } else {
            return process.env.BASE_URL;
        }
        // return "";
    }

    static getServerSetting(setting, defaultValue) {
        let finalValue = null;
        let navigationEntries = performance.getEntriesByType('navigation');

        // Check if navigation entries exist and serverTiming is available
        if (navigationEntries.length > 0 && 'serverTiming' in navigationEntries[0]) {
            let timings = navigationEntries[0].serverTiming;

            if (timings.length > 0) {
                try {
                    let settingEntry = timings.find(el => el.name === setting);
                    if (settingEntry) {
                        finalValue = settingEntry.description;
                    }
                } catch (e) {
                    console.error("Error retrieving server setting:", e.message);
                    finalValue = defaultValue;
                }
            }
        } else {
            console.warn("serverTiming is not supported or not available in this browser.");
        }

        return finalValue || defaultValue;
    }


    static getState() {
        let state = 'General';
        if (Functions.isDevelopment()) {
            if (Functions.getOriginPath().includes('8925')) {
                state = 'Limited';
            }
        } else {
            state = Functions.getServerSetting('state', 'General');
        }
        return state;
    }

    static isLimited() {
        return Functions.getState() === 'Limited';
    }

}

export default Functions;
