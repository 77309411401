<template>
    <div :class="`modal modalContainer ${size}`" :id="modalId" :ref="modalId" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header" v-if="!noTitle">
                    <h5 class="modal-title" v-html="title"></h5>
                    <button v-if="!noClose" type="button" class="btn btn-sm btn-dark" data-bs-dismiss="modal" aria-label="Close"><font-awesome-icon icon="close" /></button>
                </div>
                <div class="modal-body" v-if="!noBody">
                    <slot name="content">

                    </slot>
                </div>
                <div class="modal-footer" :style="leftFooter ? 'display: block' : ''" v-if="!noFooter">
                    <slot name="footer">

                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Modal} from "bootstrap";

export default {
    name: "BootstrapModal",
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: 'XNS-Console'
        },
        size: {
            type: String,
            required: false,
            default: '',
            options: ['modal-sm' | 'modal-lg' | 'modal-xl'],
            validator: (value) => {
                return ['', 'modal-sm' | 'modal-lg' | 'modal-xl'].includes(value);
            }
        },
        noTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        noFooter: {
            type: Boolean,
            required: false,
            default: false,
        },
        noBody: {
            type: Boolean,
            required: false,
            default: false,
        },
        clickClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        noClose: {
            type: Boolean,
            required: false,
            default: false,
        },
        leftFooter: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    watch: {
        show(isShown) {
            if (this.entity) {
                if (isShown) {
                    this.entity.show();
                } else {
                    this.entity.hide();
                }
            }
        },
    },
    created() {
        this.modalId = this.generateString();
    },
    mounted() {
        // this.$nextTick(async () => {
        //     this.entity = new Modal(this.$refs[this.modalId], {keyboard: this.clickClose, backdrop: 'static'});
        //     let myModalEl = document.getElementById(this.modalId);
        //     myModalEl.addEventListener('hidden.bs.modal', () => {
        //         this.BUS.emit('modal-closed');
        //     })
        // });
        const checkModalElement = setInterval(() => {
            const modalElement = this.$refs[this.modalId];

            // If the modal element is found, create the Bootstrap Modal instance and stop the interval
            if (modalElement) {
                this.entity = new Modal(modalElement, {
                    keyboard: this.clickClose,
                    backdrop: 'static'
                });

                modalElement.addEventListener('hidden.bs.modal', () => {
                    this.BUS.emit('modal-closed');
                    // this.$emit('modal-closed');
                });

                // Clear the interval once the modal element is found
                clearInterval(checkModalElement);
            }
        }, 100); // Check every 100ms
    },
    computed: {},
    methods: {
        generateString(length = 8) {
            let result = '';
            let characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
            let charactersLength = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }
    },
    data: () => ({
        modalId: '',
        entity: null,
    })
}
</script>

<style type="text/css" scoped>
.modal-xxl {
    --bs-modal-width: 1500px;
}
</style>
